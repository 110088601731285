<template>
  <b-container fluid="lg">
    <notifications group="notification" position="top right" />
    <b-row>
      <b-col class="mb-8">
        <div class="card card-custom card-stretch">
          <div class="card-body pt-10">
            <div class="d-flex justify-content-end"></div>
            <div class="d-flex flex-column">
              <h1 class="display-5 font-weight-bold">Loyalty Cards Upload</h1>
              <p>Make sure you enter data in the correct format and order</p>
              <form enctype="multipart/form-data">
                <input type="file" @change="onChange" accept=".csv, .xlsx" />
              </form>
              <b-button
                variant="primary"
                class="mt-8"
                :disabled="fileData.length === 0 || loading"
                @click="upload"
              >
                Upload
              </b-button>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <table
          class="table table-head-custom table-vertical-center table-head-bg"
        >
          <thead>
            <tr>
              <th>SERIAL NUMBER</th>
              <th>CARD OWNER</th>
              <th>IS ACTIVE</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, key) in fileData"
              :key="key"
              class="text-uppercase"
            >
              <td :class="{ 'border-top-0': key === 0 }">
                {{ row['SERIAL NUMBER'] }}
              </td>
              <td :class="{ 'border-top-0': key === 0 }">
                {{ row['CARD OWNER'] }}
              </td>
              <td :class="{ 'border-top-0': key === 0 }">
                {{ row['IS ACTIVE'] }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="!file" class="d-flex justify-content-center">
          Upload a CSV or XLSX file to see the preview
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import * as XLSX from 'xlsx'
import axios from 'axios'
import Vue from 'vue'

export default {
  name: 'LoyaltyCardsUpload',
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty Card Upload', route: '' },
    ])
  },
  data() {
    return {
      fileData: [],
      file: null,
      loading: false,
    }
  },
  methods: {
    onChange(event) {
      const file = event.target.files[0]
      if (!file) return

      this.file = file

      const reader = new FileReader()
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result)
        const workbook = XLSX.read(data, { type: 'array' })

        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]

        const json = XLSX.utils.sheet_to_json(worksheet)
        console.log(json)
        this.fileData = json
      }
      reader.readAsArrayBuffer(file)
    },
    async upload() {
      this.loading = true
      if (!this.file) {
        this.loading = false
        return
      }

      const formData = new FormData()
      formData.append('file', this.file)

      try {
        // Update the endpoint to point to the backend on localhost:8002
        const { data } = await axios.post(
          `${process.env.VUE_APP_LOYALTY_API_ENDPOINT_ROUTE_API_URL}/card/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        this.loading = false

        if (data.failedCards.length > 0) {
          Vue.notify({
            group: 'notification',
            type: 'error',
            title: 'Duplicate Serial Numbers',
            text: `The following serial numbers are duplicates: ${data.failedCards.join(', ')}`
          })
          return
        }

        Vue.notify({
          group: 'notification',
          type: 'success',
          title: 'Upload Successful',
          text: 'Loyalty cards have been successfully uploaded.'
        })

        // Clear file data after successful upload
        this.fileData = []
        this.file = null

      } catch (err) {
        this.loading = false
        Vue.notify({
          group: 'notification',
          type: 'error',
          title: 'Upload Failed',
          text: 'An error occurred while uploading the loyalty cards.'
        })
        console.error(err)
      }
    },
  },
}
</script>
